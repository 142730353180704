import classes from '../Sidebar.module.css';
import { SidebarItemType } from '../models/SidebarItemTypes';
import { ReactComponent as TotalAppointmentsIcon } from '../../../images/Total_Appointments.svg';
import { ReactComponent as TodayAppointmentsIcon } from '../../../images/TodayAppointments.svg';
import { ReactComponent as TomrrowAppointmentsIcon } from '../../../images/Tomrrow_Appointments.svg';
import { ReactComponent as CallListIcon } from '../../../images/CallList.svg';
import { ReactComponent as ShortCallListIcon } from '../../../images/ShortCallList.svg';
import { APP_ROUTES } from '../../../utils/constants';

export const SidebarData: SidebarItemType[] = [
  {
    id: 0,
    title: 'Total Appointments',
    path: [APP_ROUTES.appointments, '/'],
    icon: <TotalAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: 1,
    title: "Today's Appointments",
    path: [APP_ROUTES.todayAppointments],
    icon: <TodayAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: 2,
    title: "Tomorrow's Appointments",
    path: [APP_ROUTES.tomorrowAppointments],
    icon: <TomrrowAppointmentsIcon className={classes.svgIcon} />,
  },
  {
    id: 3,
    title: 'Call List',
    path: [APP_ROUTES.callList],
    icon: <CallListIcon className={classes.svgIcon} />,
  },
  {
    id: 4,
    title: 'Short-call List',
    path: [APP_ROUTES.shortCallList],
    icon: <ShortCallListIcon className={classes.svgIcon} />,
  },
  {
    id: 5,
    title: 'Reactivation List',
    path: [APP_ROUTES.reactivationList],
    icon: <ShortCallListIcon className={classes.svgIcon} />,
  },
];
