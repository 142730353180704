import React from 'react';
import classes from './Loader.module.css';

interface LoaderPropsType {
  type: string;
}

function Loader({ type }: LoaderPropsType) {
  return (
    <>
      <div
        className={
          type === 'round'
            ? classes.outer_loader_box1
            : classes.outer_loader_box2
        }
      ></div>
    </>
  );
}

export default Loader;
