export const COLOR_CODES = [
  '#6366F1',
  '#38BDF8',
  '#34D399',
  '#FBBF24',
  '#FFA726',
  '#024898',
  '#B82708',
];

export const API_ROUTES = {
  SIGN_IN: `${process.env.REACT_APP_API_BASE_URI}/user/login`,
  SIGN_UP: `${process.env.REACT_APP_API_BASE_URI}/user/signup`,
  FETCH_APPOINTMENTS: `${process.env.REACT_APP_API_BASE_URI}/user/filter-appointments`,
  FETCH_RECREATION: `${process.env.REACT_APP_API_BASE_URI}/user/recreation-list`,
};

export const APP_ROUTES = {
  appointments: '/appointments',
  todayAppointments: '/appointments/today',
  tomorrowAppointments: '/appointments/tomorrow',
  callList: '/call-list/call',
  shortCallList: '/call-list/short-call',
  reactivationList: '/call-list/reactivation',
};
