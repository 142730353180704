import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Table from '../components/Table';
import { InputFieldType } from '../components/Input';
import { API_ROUTES } from '../utils/constants';
import Loader from '../components/Loader/Loader';
import { TableTypes } from '../components/Table/tableTypes';

const Appointments = () => {
  const params = useParams();
  const [fetching, setFetching] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState<TableTypes.TableBody[]>(
    []
  );

  useEffect(() => {
    const getAppointmentsData = () => {
      const todayDate = new Date();

      const tomorrowDate = new Date();

      tomorrowDate.setDate(tomorrowDate.getDate() + 1);

      let url = API_ROUTES.FETCH_APPOINTMENTS;

      if (params.apptSlot === 'today') {
        url += `?apptDate=${dayjs(todayDate).format('YYYY-MM-DD')}`;
      } else if (params.apptSlot === 'tomorrow') {
        url += `?apptDate=${dayjs(tomorrowDate).format('YYYY-MM-DD')}`;
      }

      fetch(url)
        .then((res) => res.json())
        .then(({ data }: { data: any[] }) => {
          setAppoinmentData(
            data.map(({ _id, ...apptData }) => ({
              ...apptData,
              id: _id,
            }))
          );
        })
        .catch((err) => {
          console.log('Error has occurred while fetching appointments::', err);
        })
        .finally(() => {
          setFetching(false);
        });
    };

    setFetching(true);
    getAppointmentsData();
  }, [params.apptSlot]);

  const headers: TableTypes.TableHeader[] = [
    {
      value: 'patientName',
      displayName: 'Patient Name',
      searchable: true,
      filterType: InputFieldType.TEXT,
    },
    {
      value: 'apptDate',
      displayName: 'Appointment Date',
      formatter: (date) => dayjs(date).format('MM/DD/YYYY HH:mm'),
    },
    { value: 'minutes', displayName: 'Appt Duration(mins)' },
    { value: 'lomaScore', displayName: 'Loma Score' },
    { value: 'primaryDoctor', displayName: 'Primary Doctor' },
    { value: 'preferredHygienist', displayName: 'Hygienist' },
    {
      value: 'apptScheduledWith',
      displayName: 'Appt Scheduled With',
      expandable: true,
    },
    { value: 'apptNotes', displayName: 'Appt Notes', expandable: true },
    { value: 'apptDetails', displayName: 'Appt Details', expandable: true },
  ];

  if (fetching) {
    return (
      <div className="container none-data-display">
        <div className="loader">
          <Loader type="round" />
        </div>
      </div>
    );
  }

  return <Table headers={headers} body={appoinmentData} />;
};

export default Appointments;
