import React, { useState } from 'react';
import './index.css';

export enum InputFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  LIST = 'list',
  PASSWORD = 'password',
  EMAIL = 'email',
}

interface InputProps {
  type: InputFieldType;
  label: string;
  placeholder: string;
  isRequired: boolean;
  color: string;
  onValueChange: (item: string) => void;
  onEnterPress: (item: string) => void;
}

const defaultInputProps = {
  type: InputFieldType.TEXT,
  label: '',
  placeholder: '',
  isRequired: false,
  color: '',
  onValueChange: () => {},
  onEnterPress: () => {},
};

function Input(props: InputProps) {
  const [number, setNumber] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [show /*, setShow */] = useState(false);
  return (
    <div className="margin_top_1">
      <p
        className={`label ${props.label ? 'background-white' : ''} ${
          props.color === 'red' ? 'red' : ''
        }`}
      >
        {props.label}
        <span className="red">{props.isRequired ? ' *' : ''}</span>
      </p>

      {props.type === InputFieldType.TEXT && (
        <input
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setText(e.target.value);
          }}
          value={text}
          placeholder={props.placeholder}
        />
      )}

      {props.type === InputFieldType.NUMBER && (
        <input
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setNumber(e.target.value);
          }}
          value={number}
          placeholder={props.placeholder}
        />
      )}

      {props.type === InputFieldType.PASSWORD && (
        <>
          <div className="password">
            <input
              className={`text_input ${
                props.color === 'red' ? 'border-red' : 'border-gray'
              }`}
              type={show ? 'text' : props.type}
              onChange={(e) => {
                props.onValueChange(e.target.value);
                setPassword(e.target.value);
              }}
              value={password}
              placeholder={props.placeholder}
            />
            {/* <span className="eye" onClick={()=>setShow(!show)}>WIP</span> */}
          </div>
        </>
      )}

      {props.type === InputFieldType.EMAIL && (
        <input
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={props.placeholder}
        />
      )}

      {props.type === InputFieldType.DATE && (
        <input
          className={`text_input ${
            props.color === 'red' ? 'border-red' : 'border-gray'
          }`}
          type={props.type}
          onChange={(e) => {
            props.onValueChange(e.target.value);
            setDate(e.target.value);
          }}
          value={date}
          placeholder={props.placeholder}
        />
      )}
    </div>
  );
}
Input.defaultProps = defaultInputProps;
export default Input;
