import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classes from './Login.module.css';
import Input, { InputFieldType } from '../../components/Input';
import Checkbox from '../../components/form/Checkbox';
import axios from 'axios';
import { API_ROUTES } from '../../utils/constants';
import { isEmpty } from 'lodash';
import { SignInContext, SignInContextType } from '../../contexts/signin';
import Button, { ButtonType, Type } from '../../components/Button';

interface LoginInput {
  username: string;
  password: string;
}

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors]: any = useState({});
  const [isSubmit, setIsSubmit] = useState(0);
  let navigate = useNavigate();
  const isEnabled = email.length > 0 && password.length > 0;

  const { onSignIn } = useContext<SignInContextType>(SignInContext);

  const LoginInput: LoginInput = {
    username: email,
    password: password,
  };

  const emailHandler = (userEmail: string) => {
    setEmail(userEmail);
    LoginInput.username = userEmail;
    if (isSubmit) {
      setFormErrors(formValidation(LoginInput));
    }
  };

  const passwordHandler = (userPassword: string) => {
    setPassword(userPassword);
    LoginInput.password = userPassword;
    if (isSubmit) {
      setFormErrors(formValidation(LoginInput));
    }
  };

  const formValidation = (formValues: LoginInput) => {
    const errors: any = {};
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (!regex.test(formValues.username)) {
      errors.username = 'Must be a valid email!';
    }
    if (formValues.password.length < 8) {
      errors.password = 'Must be atleast 8 characters !';
    }
    return errors;
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    setIsSubmit(1);
    setFormErrors(formValidation(LoginInput));
    if (isEmpty(formValidation(LoginInput)) === true) {
      axios
        .post(API_ROUTES.SIGN_IN, LoginInput)
        .then((res) => {
          if (res.data.status === 'success') {
            onSignIn();
            navigate('/appointments');
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className={classes.loginPage}>
      <form className={classes.loginForm} onSubmit={submitHandler}>
        <p className={classes.loginHead}>Login to your account</p>

        <p className={classes.loginDesc}>
          Login with your data you entered during sign up.
        </p>

        <div className={classes.inputField}>
          <Input
            type={InputFieldType.TEXT}
            label="Email"
            placeholder="xyz@gmail.com"
            onValueChange={emailHandler}
          />
        </div>

        {!!formErrors.username && (
          <p className={classes.error}>{formErrors.username}</p>
        )}

        <div className={classes.inputPasswordField}>
          <Input
            type={InputFieldType.PASSWORD}
            label="Password"
            placeholder={'Type Password'}
            onValueChange={passwordHandler}
          />
        </div>

        {!!formErrors.password && (
          <p className={classes.error}>{formErrors.password}</p>
        )}

        <div className={classes.middleFieldLoginBox}>
          <div className={classes.middleFieldLogin}>
            <Checkbox />
            <p className={classes.loginCheckBoxText}>Keep me logged in</p>
          </div>
          <div className={classes.right}>
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a href="#">Forgot password?</a>
          </div>
        </div>

        <div className={classes.loginButton}>
          <Button
            type={Type.SUBMIT}
            disabled={!isEnabled}
            text="Login"
            buttonType={ButtonType.FILLED}
          />
        </div>
        <div className={classes.loginFooter}>
          <p>
            Don't have an account? <Link to="/signup">Sign Up</Link>{' '}
          </p>
        </div>
      </form>
    </div>
  );
}

export default Login;
