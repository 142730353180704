import React from 'react';
import classes from './HeaderItemOverlay.module.css';
interface ActionItemProps {
  title: string;
  item: string;
}

function ActionItem({ title, item }: ActionItemProps) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>{title}</div>
      <div className={classes.item}>
        <p>{item}</p>
      </div>
    </div>
  );
}

export default ActionItem;
