import dayjs from 'dayjs';
import { TableTypes } from '../components/Table/tableTypes';
import { InputFieldType } from '../components/Input';

export namespace CallListConf {
  export type CallistParams = {
    type: 'call' | 'shot-call' | 'reactivation';
  };

  export const callListFields: TableTypes.TableHeader[] = [
    {
      value: 'patientName',
      displayName: 'Patient Name',
      searchable: true,
      filterType: InputFieldType.TEXT,
    },
    {
      value: 'apptDate',
      displayName: 'Appointment Date',
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY HH:mm'),
    },
    { value: 'minutes', displayName: 'Appt Duration(mins)' },
    { value: 'lomaScore', displayName: 'Loma Score' },
    { value: 'primaryDoctor', displayName: 'Primary Doctor' },
    { value: 'patientPhone', displayName: 'Contact Number' },
    {
      value: 'apptScheduledWith',
      displayName: 'Appt Scheduled With',
      expandable: true,
    },
    { value: 'apptNotes', displayName: 'Appt Notes', expandable: true },
    { value: 'apptDetails', displayName: 'Appt Details', expandable: true },
  ];

  export const recreationListFields: TableTypes.TableHeader[] = [
    {
      value: 'patientName',
      displayName: 'Patient Name',
      searchable: true,
      filterType: InputFieldType.TEXT,
    },
    { value: 'preferredProvider', displayName: 'Preferred Provider' },
    { value: 'lomaScore', displayName: 'Loma Score' },
    { value: 'preferredHygienist', displayName: 'Preferred Hygienist' },
    { value: 'phone', displayName: 'Contact Number', expandable: true },
    {
      value: 'lastHygieneProcedure',
      displayName: 'Last Hygiene Procedure',
      expandable: true,
    },
    { value: 'lastHygieneProvider', displayName: 'Last Hygiene Provider' },
    {
      value: 'lastHygieneDos',
      displayName: 'Last Hygiene DOS',
      expandable: true,
      formatter: (date: string) => dayjs(date).format('MM/DD/YYYY HH:mm'),
    },
  ];

  export const getCallListFields = (
    listType: CallistParams
  ): TableTypes.TableHeader[] =>
    listType.type === 'reactivation' ? recreationListFields : callListFields;
}
