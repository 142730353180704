import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _orderBy from 'lodash/orderBy';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Arrow } from '../../images/arrow.svg';
import { ReactComponent as UpDownArrow } from '../../images/up_down_arrow.svg';
import { ReactComponent as SortArrow } from '../../images/sort_arrow.svg';
import avtarimg from '../../images/Avatar-1.png';
import './table.css';
import Checkbox from '../form/Checkbox';
import TableHeaderBar from './TableHeaderBar';
import Input, { InputFieldType } from '../Input';
import { TableTypes } from './tableTypes';

const defaultProps: TableTypes.TableProps = {
  selectable: false,
  body: [],
  pageSizes: [5, 10, 20],
  showPagination: true,
  headers: [],
  defaultPageSize: 10,
  showAvatarFirst: false,
  customAvatar: null,
  tableActions: [],
  sortedBy: '',
  sortOrder: TableTypes.TableSort.NO_SORT,
};

const Table = ({
  body,
  headers,
  pageSizes,
  showPagination,
  tableActions,
  customAvatar,
  defaultPageSize,
  showAvatarFirst,
  selectable,
  sortedBy,
  sortOrder,
}: TableTypes.TableProps) => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const [totalPages, setTotalPages] = useState(
    Math.ceil(body.length / pageSize)
  );

  const [pageNumber, setPageNumber] = useState<number>(body.length ? 0 : -1);

  const pageOffset: number = pageNumber * pageSize;

  const pageOffsetEnd: number = pageOffset + pageSize;

  const memoisedTableSettings: TableTypes.TableSettings = useMemo(() => {
    const memoisedHeaders: TableTypes.ColumnsSetting = headers.reduce(
      (newHeaders, column) => {
        return {
          ...newHeaders,
          [column.value]: {
            ...column,
            searchValue: '',
            searchEnabled: false,
            currentSort:
              column.value === sortedBy
                ? sortOrder
                : TableTypes.TableSort.NO_SORT,
          },
        };
      },
      {}
    );

    const column: TableTypes.ColumnSetting = memoisedHeaders[sortedBy];

    const sortedBody: TableTypes.TableBody[] =
      sortOrder !== TableTypes.TableSort.NO_SORT
        ? _orderBy(body, [column.value], [sortOrder])
        : [...body];

    const memoisedBody: TableTypes.RowsSetting[] = sortedBody.map(
      (newBody) => ({
        ...newBody,
        selected: false,
        currentCollapseState: TableTypes.CollapseState.COLLAPSED,
      })
    );
    return {
      rows: memoisedBody,
      columns: memoisedHeaders,
    };
  }, [headers, body, sortOrder, sortedBy]);

  const [tableSettings, setTableSettings] = useState<TableTypes.TableSettings>(
    memoisedTableSettings
  );

  const nRowsSelected = useMemo(() => {
    return Object.values(tableSettings.rows).filter((row) => row.selected)
      .length;
  }, [tableSettings.rows]);

  useEffect(() => {
    const newTotalPages = Math.ceil(tableSettings.rows.length / pageSize);
    setTotalPages(newTotalPages);
    setPageNumber(0);
  }, [pageSize, tableSettings.rows.length]);

  useEffect(() => {
    setTotalPages(Math.ceil(tableSettings.rows.length / pageSize));
  }, [pageSize, tableSettings.rows.length]);

  const handleRowClick = (rowId: string) => {
    const currentCollapseState = tableSettings.rows.find(
      (row) => row.id === rowId
    )?.currentCollapseState;
    setTableSettings({
      ...tableSettings,
      rows: tableSettings.rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            currentCollapseState:
              currentCollapseState === TableTypes.CollapseState.COLLAPSED
                ? TableTypes.CollapseState.EXPANDED
                : TableTypes.CollapseState.COLLAPSED,
          };
        } else {
          return row;
        }
      }),
    });
  };

  const handleRowsSelect = (selected: boolean) => {
    setTableSettings({
      ...tableSettings,
      rows: tableSettings.rows.map((row) => ({
        ...row,
        selected,
      })),
    });
  };

  const handleRowSelect = (selected: boolean, rowId: string) => {
    setTableSettings({
      ...tableSettings,
      rows: tableSettings.rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            selected,
          };
        } else {
          return row;
        }
      }),
    });
  };

  const handleChangePage = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
  };

  const handleColumnClick = (column: TableTypes.ColumnSetting) => {
    const { columns }: TableTypes.TableSettings = memoisedTableSettings;
    let initialRows: TableTypes.RowsSetting[] = [...tableSettings.rows];
    let newSort: TableTypes.TableSort = TableTypes.TableSort.NO_SORT;
    if (column.currentSort === TableTypes.TableSort.ASC) {
      // DESC
      initialRows = _orderBy(
        initialRows,
        [column.value],
        [TableTypes.TableSort.DESC]
      );
      newSort = TableTypes.TableSort.DESC;
    } else {
      // ASC
      initialRows = _orderBy(
        initialRows,
        [column.value],
        [TableTypes.TableSort.ASC]
      );
      newSort = TableTypes.TableSort.ASC;
    }
    setTableSettings({
      columns: {
        ...columns,
        [column.value]: {
          ...columns[column.value],
          currentSort: newSort,
        },
      },
      rows: initialRows,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleFilterChange = (
    fieldName: string,
    fieldValue: string,
    fieldType: InputFieldType
  ) => {
    const rowData: TableTypes.RowsSetting[] = body.map((newBody) => ({
      ...newBody,
      selected: false,
      currentCollapseState: TableTypes.CollapseState.COLLAPSED,
    }));

    if (fieldValue) {
      switch (fieldType) {
        case InputFieldType.TEXT:
          setTableSettings({
            ...tableSettings,
            rows: rowData.filter((row) =>
              row[fieldName].toLowerCase().includes(fieldValue.toLowerCase())
            ),
          });
          break;
        case InputFieldType.DATE:
          setTableSettings({
            ...tableSettings,
            rows: rowData.filter(
              (row) => row[fieldName].substr(0, 10) === fieldValue
            ),
          });
          break;
        case InputFieldType.NUMBER:
          setTableSettings({
            ...tableSettings,
            rows: rowData.filter((row) => row[fieldName] === fieldValue),
          });
          break;
      }
    } else {
      setTableSettings({
        ...tableSettings,
        rows: rowData,
      });
    }
  };

  const renderBody = () =>
    tableSettings.rows
      .slice(pageOffset, pageOffset + pageSize)
      .map((row, idx) => {
        const columns = Object.values(tableSettings.columns);

        const isRowSelected = row.selected;

        const colSpan = Math.ceil(
          (columns.filter((header) => !header.expandable).length +
            1 +
            tableActions?.length +
            (showAvatarFirst ? 1 : 0) +
            (selectable ? 1 : 0)) /
            columns.filter((header) => header.expandable).length
        );

        const isRowExpanded: boolean =
          row.currentCollapseState === TableTypes.CollapseState.EXPANDED;

        return (
          <Fragment key={row.id}>
            <tr
              className={`table-row ${isRowExpanded ? ' row-expadable' : ''}`}
              onClick={() => handleRowClick(row.id)}
            >
              <>
                {customAvatar ||
                  (showAvatarFirst && (
                    <td>
                      <img
                        src={avtarimg}
                        alt=""
                        style={{ width: 45, height: 45 }}
                        className="rounded-circle"
                      />
                    </td>
                  ))}
                <td>
                  <div className="arrowImage">
                    <Arrow />
                  </div>
                </td>
                {selectable && (
                  <td>
                    <Checkbox
                      checked={isRowSelected}
                      onCheck={(checked) => handleRowSelect(checked, row.id)}
                    />
                  </td>
                )}
                {columns
                  .filter((header) => !header.expandable)
                  .map((header) => (
                    <td key={header.value}>
                      {header.formatter
                        ? header.formatter(row[header.value])
                        : row[header.value]}
                    </td>
                  ))}
                {tableActions?.map((action) => (
                  <>
                    <td>
                      <div className="pading-left">{action.component}</div>
                    </td>
                  </>
                ))}
              </>
            </tr>
            {isRowExpanded && (
              <tr
                key={row.id + idx + 1}
                className={`row-expanded${idx % 2 === 0 ? ' even' : ''}`}
              >
                {columns
                  .filter((header) => header.expandable)
                  .map((header) => (
                    <td colSpan={colSpan} key={header.value}>
                      <div className="d-flex flex-column">
                        <span className="header-expanded">
                          {header.displayName}
                        </span>
                        <span className="table-data-expanded">
                          {header.formatter
                            ? header.formatter(row[header.value])
                            : row[header.value]}
                        </span>
                      </div>
                    </td>
                  ))}
              </tr>
            )}
          </Fragment>
        );
      });

  const renderFilter = (
    searchable: boolean | undefined,
    filterType: InputFieldType | undefined,
    displayName: string,
    value: string
  ) => {
    return (
      <>
        <div>
          {searchable && filterType === InputFieldType.TEXT && (
            <div className="filter">
              <Input
                type={InputFieldType.TEXT}
                placeholder={`Search ${displayName}`}
                onValueChange={(data) =>
                  handleFilterChange(value, data, filterType)
                }
              />
            </div>
          )}

          {searchable && filterType === InputFieldType.DATE && (
            <div className="filter">
              <Input
                type={InputFieldType.DATE}
                placeholder={displayName}
                onValueChange={(data) =>
                  handleFilterChange(value, data, filterType)
                }
              />
            </div>
          )}

          {searchable && filterType === InputFieldType.NUMBER && (
            <div className="filter">
              <Input
                type={InputFieldType.NUMBER}
                placeholder={displayName}
                onValueChange={(data) =>
                  handleFilterChange(value, data, filterType)
                }
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!!body.length ? (
        <div>
          <div>
            {!!nRowsSelected && (
              <TableHeaderBar nRowsSelected={nRowsSelected} />
            )}
            <table className="table table-borderless">
              <thead className="header_color">
                <tr className="table-head">
                  {showAvatarFirst && <th />}
                  <th />
                  {selectable && (
                    <th>
                      <Checkbox onCheck={handleRowsSelect} />
                    </th>
                  )}
                  {Object.values(tableSettings.columns)
                    .filter((column) => !column.expandable)
                    .map((column, index) => (
                      <th key={column.value + index}>
                        <div className="d-flex flex-column align-items-start">
                          {renderFilter(
                            column.searchable,
                            column.filterType,
                            column.displayName,
                            column.value
                          )}
                          <div className="d-flex align-items-center">
                            <div className="d-flex flex-column">
                              <span
                                className="table-header-text"
                                onClick={() => handleColumnClick(column)}
                              >
                                {column.displayName}
                              </span>
                            </div>
                            {column.currentSort ===
                              TableTypes.TableSort.NO_SORT && <UpDownArrow />}
                            {column.currentSort ===
                              TableTypes.TableSort.ASC && <SortArrow />}
                            {column.currentSort ===
                              TableTypes.TableSort.DESC && (
                              <SortArrow className="sort-arrow--down" />
                            )}
                          </div>
                        </div>
                      </th>
                    ))}
                  {tableActions?.map((action) => (
                    <th>{action.text}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{renderBody()}</tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-between table-footer">
            <span>
              Showing <strong>{pageOffset + 1}</strong> to{' '}
              <strong>
                {pageOffsetEnd > tableSettings.rows.length
                  ? tableSettings.rows.length
                  : pageOffsetEnd}
              </strong>{' '}
              of <strong>{tableSettings.rows.length}</strong> results
            </span>

            <div className="d-flex align-items-center">
              <label htmlFor="pageSize">Select:</label>
              <select
                value={pageSize}
                className="mx-2"
                id="pageSize"
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handlePageSizeChange(+e.target.value)
                }
              >
                {pageSizes.map((pageSize) => (
                  <option key={pageSize}>{pageSize}</option>
                ))}
              </select>
            </div>

            {showPagination && (
              <ReactPaginate
                previousLabel={<Arrow className="left-arrow" />}
                breakLabel="..."
                nextLabel={<Arrow className="right-arrow" />}
                pageCount={totalPages}
                forcePage={pageNumber}
                onPageChange={handleChangePage}
                containerClassName="paginationBttns"
                previousLinkClassName="previousBttn"
                nextLinkClassName="nextBttn"
                disabledClassName="paginationDisabled"
                activeClassName="paginationActive"
              />
            )}
          </div>
        </div>
      ) : (
        'No records found'
      )}
    </>
  );
};

Table.defaultProps = defaultProps;

export default Table;
