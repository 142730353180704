import React from 'react';

import classes from './ActionItem.module.css';

interface ActionItemProps {
  children: JSX.Element | JSX.Element[] | boolean;
  icon: string | JSX.Element;
  tooltip?: string;
  onClick: () => void;
}

const defaultProps = {
  children: '',
  icon: '',
  tooltip: 'Action Item',
  onClick: () => null,
};

const ActionItem = (props: ActionItemProps): JSX.Element => {
  const { icon, tooltip, onClick, children } = props;
  return (
    <div className={classes.headerIcon} onClick={onClick}>
      {typeof icon === 'string' ? <img src={icon} alt={tooltip} /> : icon}
      <span className={classes.tooltiptext}>{tooltip}</span>
      <div className={classes.notificationBox}>{children}</div>
    </div>
  );
};

ActionItem.defaultProps = defaultProps;

export default ActionItem;
