import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from '../components/form/Checkbox';
import Loader from '../components/Loader/Loader';
import Table from '../components/Table';
import { API_ROUTES } from '../utils/constants';
import { TableTypes } from '../components/Table/tableTypes';
import { CallListConf } from './callListConf';

type CallistParams = {
  type: 'call' | 'shot-call' | 'reactivation';
};

const CallList = () => {
  const callListType = useParams<CallistParams>();
  const [fetching, setFetching] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState<TableTypes.TableBody[]>(
    []
  );

  const sortOrder: TableTypes.TableSort = useMemo(() => {
    if (callListType.type === 'call') {
      return TableTypes.TableSort.ASC;
    } else {
      return TableTypes.TableSort.DESC;
    }
  }, [callListType]);

  const headers: TableTypes.TableHeader[] = useMemo(() => {
    return CallListConf.getCallListFields(callListType as CallistParams);
  }, [callListType]);

  useEffect(() => {
    const getAppointmentsData = () => {
      let url = '';

      if (callListType.type === 'reactivation') {
        url = API_ROUTES.FETCH_RECREATION;
      } else if (callListType.type === 'shot-call') {
        url = `${API_ROUTES.FETCH_APPOINTMENTS}?lomaScoreSort=-1`;
      } else {
        url = `${API_ROUTES.FETCH_APPOINTMENTS}?lomaScoreSort=1`;
      }

      fetch(url)
        .then((res) => res.json())
        .then(({ data }: { data: any[] }) => {
          setAppoinmentData(
            data.map(({ _id, ...apptData }) => ({
              ...apptData,
              id: _id,
            }))
          );
        })
        .catch((err) => {
          console.log('Error has occurred while fetching appointments::', err);
        })
        .finally(() => {
          setFetching(false);
        });
    };

    setFetching(true);
    getAppointmentsData();
  }, [callListType]);

  if (fetching) {
    return (
      <div className="container none-data-display">
        <div className="loader">
          <Loader type="round" />
        </div>
      </div>
    );
  }

  return (
    <Table
      headers={headers}
      sortedBy="lomaScore"
      sortOrder={sortOrder}
      body={appoinmentData}
      tableActions={[
        { text: 'Contacted', component: <Checkbox className="m-2" /> },
      ]}
    />
  );
};

export default CallList;
