import { createContext, useMemo, useState } from 'react';

interface SignInConfig {
  isSignedIn: boolean;
}

interface SignInContextProviderProps {
  children: string | JSX.Element | JSX.Element[] | null;
}

const signInConfig: SignInConfig = {
  isSignedIn: false,
};

export interface SignInContextType {
  isSignedIn: boolean;
  onSignIn: () => void;
  onSignOut: () => void;
}

export const SignInContext = createContext({
  isSignedIn: false,
} as SignInContextType);

const SignInContextProvider = ({ children }: SignInContextProviderProps) => {
  const [signInState, setSignInState] = useState<SignInConfig>(signInConfig);

  const onSignIn = () => {
    setSignInState({
      ...signInState,
      isSignedIn: true,
    });
  };

  const onSignOut = () => {
    setSignInState({
      ...signInState,
      isSignedIn: false,
    });
  };

  const signInValue = useMemo(
    () => ({
      ...signInState,
      onSignIn,
      onSignOut,
    }),
    // @TODO: Figure out this exhaustive-deps rules
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signInState]
  );

  return (
    <SignInContext.Provider value={signInValue}>
      {children}
    </SignInContext.Provider>
  );
};

export default SignInContextProvider;
