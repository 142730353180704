import React from 'react';
import classes from './Button.module.css';

export enum ButtonType {
  FILLED = 1,
  OUTLINE = 2,
  SUCCESS = 3,
  DANGER = 4,
}

export enum Type {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

interface ButtonProps {
  onClick: () => void;
  text: string;
  buttonType: ButtonType;
  type: Type;
  disabled: boolean;
}

const defaultButtonProps = {
  onClick: () => {},
  text: 'button',
  buttonType: ButtonType.FILLED,
  type: Type.SUBMIT,
  disabled: false,
};

function Button(props: ButtonProps) {
  const { type, onClick, disabled, text, buttonType } = props;
  return (
    <div className={classes.buttonComponent}>
      {
        <div className={classes['m-2']}>
          {buttonType === ButtonType.FILLED && (
            <button
              className={`${classes.button} ${classes.filled}`}
              type={type}
              disabled={disabled}
              onClick={onClick}
              data-testid="my-button"
            >
              {text}
            </button>
          )}
        </div>
      }
      {
        <div className={classes['m-2']}>
          {buttonType === ButtonType.SUCCESS && (
            <button
              className={`${classes.button} ${classes.success}`}
              type={type}
              disabled={disabled}
              onClick={onClick}
            >
              {text}
            </button>
          )}
        </div>
      }
      {
        <div className={classes['m-2']}>
          {buttonType === ButtonType.OUTLINE && (
            <button
              className={`${classes.button} ${classes.outline}`}
              type={type}
              disabled={disabled}
              onClick={onClick}
            >
              {text}
            </button>
          )}
        </div>
      }
      {
        <div className={classes['m-2']}>
          {buttonType === ButtonType.DANGER && (
            <button
              className={`${classes.button} ${classes.danger}`}
              type={type}
              disabled={disabled}
              onClick={onClick}
            >
              {text}
            </button>
          )}
        </div>
      }
    </div>
  );
}

Button.defaultProps = defaultButtonProps;
export default Button;
