import React, { useContext } from 'react';
import classes from './Sidebar.module.css';
import styled from 'styled-components';
import SidebarContext from '../../contexts/sideBar';
import { SidebarItemType } from './models/SidebarItemTypes';
import SidebarItem from './SidebarItem/SidebarItem';

interface SidebarProps {
  sidebarData: SidebarItemType[];
}
const Aside = styled.aside<{ toggleSideBar: boolean }>`
  position: fixed;
  left: 0;
  min-width: 50px;
  height: 90vh;
  background-color: #ffffff;
  font-size: 17px;
  line-height: 22px;
  font-style: normal;
  box-shadow: 3px 0 5px -5px rgba(0, 0, 0, 0.15);
`;

const Sidebar = ({ sidebarData }: SidebarProps) => {
  const { open, onChange } = useContext(SidebarContext);
  return (
    <>
      <Aside
        toggleSideBar={open}
        onMouseEnter={() => onChange(true, 'hover')}
        onMouseLeave={() => onChange(false, 'hover')}
      >
        <div className="d-flex flex-column justify-content-end p-3">
          <div>
            {sidebarData.map((item: SidebarItemType) => {
              return <SidebarItem key={item.id} item={item} />;
            })}
          </div>
          {open && <div className={classes.copyRight}>©2022 by Truro Inc.</div>}
        </div>
      </Aside>
    </>
  );
};

export default Sidebar;
