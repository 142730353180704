import { createContext } from 'react';

export type OpenedBy = 'hover' | 'hamburger-click' | '';

interface SideBarConfig {
  open: boolean;
  openedBy: OpenedBy;
  onChange: (open: boolean, openedBy: OpenedBy) => void;
}

export const sideBarConfig: SideBarConfig = {
  open: false,
  openedBy: '',
  onChange: () => {},
};

const SidebarContext = createContext(sideBarConfig);

export default SidebarContext;
