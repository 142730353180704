import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import Sidebar from '../components/SideBar/index';
import SidebarContext, { OpenedBy, sideBarConfig } from '../contexts/sideBar';
import { SignInContext, SignInContextType } from '../contexts/signin';
import { SidebarData } from '../components/SideBar/SidebarItem/SidebarData';

const Main = styled.main<{ open: boolean; openedBy: OpenedBy }>`
  overflow: auto;
  max-height: calc(100vh - 91px);
  min-width: 700px;
  background: #f4f8ff;
  padding: 24px;
  transition: 350ms;
  margin-top: 91px;
  margin-left: ${({ open, openedBy }) =>
    open && openedBy === 'hamburger-click' ? '240px' : '50px'};
  width: ${({ open, openedBy }) =>
    open && openedBy === 'hamburger-click'
      ? 'calc(100% - 240px)'
      : 'calc(100% - 50px)'};
`;

const Layouts = () => {
  const location = useLocation();

  const { isSignedIn } = useContext<SignInContextType>(SignInContext);

  const [sideBarState, setSideBarState] = useState(sideBarConfig);

  const handleChange = (open: boolean, openedBy: OpenedBy) => {
    if (
      sideBarState.open &&
      sideBarState.openedBy === 'hamburger-click' &&
      openedBy === 'hover'
    )
      return;
    setSideBarState({
      ...sideBarState,
      open,
      openedBy,
    });
  };

  return (
    <>
      {isSignedIn ? (
        <>
          <SidebarContext.Provider
            value={{ ...sideBarState, onChange: handleChange }}
          >
            <Header />
            <Sidebar sidebarData={SidebarData} />
          </SidebarContext.Provider>
          <Main open={sideBarState.open} openedBy={sideBarState.openedBy}>
            <Outlet />
          </Main>
        </>
      ) : (
        <Navigate to="/login" state={{ from: location }} />
      )}
    </>
  );
};

export default Layouts;
