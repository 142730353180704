import React, { useContext } from 'react';
import styled from 'styled-components';
import { SidebarItemType } from '../models/SidebarItemTypes';
import { Link, useLocation } from 'react-router-dom';
import classes from '../Sidebar.module.css';
import classNames from 'classnames';
import SidebarContext from '../../../contexts/sideBar';

interface SidebarItemProps {
  item: SidebarItemType;
}

const SidebarLink = styled.div<{ toggleSideBar: boolean }>`
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #52575c;
  line-height: 22px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 12.5px;
  transition: width 0.3s;
  display: ${({ toggleSideBar }) => (toggleSideBar ? 'flex' : 'none')};
`;

const SidebarLinkContainer = styled(Link)`
  min-height: 48px;
  cursor: pointer;
  text-decoration: none;
`;
function SidebarItem({ item }: SidebarItemProps) {
  const { open } = useContext(SidebarContext);
  const location = useLocation();

  return (
    <>
      <SidebarLinkContainer
        key={item.id}
        to={item.path[0]}
        className={classNames(
          'd-flex align-items-center',
          classes.sidebarItem,
          { [classes.activeRoute]: item.path.indexOf(location.pathname) !== -1 }
        )}
      >
        <div>{item.icon}</div>
        <SidebarLink toggleSideBar={open}>
          <span className={classes.SidebarLabel}>{item.title}</span>
        </SidebarLink>
      </SidebarLinkContainer>
    </>
  );
}

export default SidebarItem;
