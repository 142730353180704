import React, { useContext } from 'react';
import classes from './HeaderItemOverlay.module.css';
import { ReactComponent as LogoutIcon } from '../../images/logout_icon.svg';
import { SignInContext } from '../../contexts/signin';

interface HeaderMenuProps {
  name: string;
  designation?: string;
  avatar: JSX.Element;
}

function HeaderUserOverlay({ name, designation, avatar }: HeaderMenuProps) {
  const { onSignOut } = useContext(SignInContext);

  return (
    <div className={classes.mainUserContainer}>
      <div className={classes.avatar}>{avatar}</div>
      <div className={`${classes.nameContainer} d-flex flex-column`}>
        <div className={classes.headername}>{name}</div>
        <div className={classes.headersubname}>{designation}</div>
      </div>

      <div className={classes.logout} onClick={onSignOut}>
        Logout{' '}
        <button>
          <LogoutIcon />{' '}
        </button>
      </div>
    </div>
  );
}

export default HeaderUserOverlay;
