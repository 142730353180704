import React, { useContext, useState } from 'react';
import classes from './Header.module.css';
import Search from './Search';
import { ReactComponent as SettingIcon } from '../../images/setting_icon.svg';
import { ReactComponent as NotificationIcon } from '../../images/logo/notification_icon.svg';
import { ReactComponent as HamburgerIcon } from '../../images/hamburger.svg';
import HeaderMenu from './HeaderMenu';
import SidebarContext from '../../contexts/sideBar';
import { ReactComponent as Logo } from '../../images/logo/Truro_logo.svg';
import ActionItem from '../ActionItem';
import HeaderItemOverlay from '../HeaderItemOverlay/HeaderItemOverlay';
import HeaderUserOverlay from '../HeaderItemOverlay/HeaderUserOverlay';
import { ReactComponent as Avatar } from '../../images/avatar.svg';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const { open, onChange } = useContext(SidebarContext);

  const [showSetting, setShowSetting] = useState(false);

  const [showNotification, setShowNotification] = useState(false);

  const [showLogout, setShowLogout] = useState(false);

  const onSttingIconClick = () => {
    setShowSetting(!showSetting);
  };

  const onNotificationIconClick = () => {
    setShowNotification(!showNotification);
  };

  const onLogoutClick = () => {
    setShowLogout(!showLogout);
  };

  return (
    <header
      className={`d-flex align-items-center justify-content-between ${classes.headerContainer}`}
    >
      <div
        className={`d-flex justify-content-start align-items-center ${classes.headerIcon}`}
      >
        <HamburgerIcon
          style={{ fontSize: 30, cursor: 'pointer' }}
          onClick={() => onChange(!open, 'hamburger-click')}
        />
        <Logo className={classes.logo} onClick={() => navigate('/')} />
        <Search />
      </div>
      <nav
        className={`d-flex align-items-center justify-content-end ${classes.headerRightContainer}`}
      >
        <ActionItem
          icon={<SettingIcon />}
          tooltip="Settings"
          onClick={onSttingIconClick}
        />
        {showSetting && (
          <div className={classes.settingBox}>
            <HeaderItemOverlay title="Settings" item="Work in Progress!!!" />
          </div>
        )}
        <ActionItem
          icon={<NotificationIcon />}
          tooltip="Notifications"
          onClick={onNotificationIconClick}
        />
        {showNotification && (
          <div className={classes.notificationBox}>
            <HeaderItemOverlay
              title="Notifications"
              item={'Work in Progress!!!'}
            />
          </div>
        )}
        <div onMouseEnter={onLogoutClick} onMouseLeave={onLogoutClick}>
          <HeaderMenu name="John Doe" designation="Dentist" avatar={<Avatar />}>
            {showLogout && (
              <HeaderUserOverlay name="John Doe" avatar={<Avatar />} />
            )}
          </HeaderMenu>
        </div>
      </nav>
    </header>
  );
}

export default Header;
