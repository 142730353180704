import React from 'react';
import classes from './Header.module.css';
import { ReactComponent as SearchIcon } from '../../images/logo/search_icon.svg';

function Search() {
  return (
    <div className={classes.search}>
      <div className={classes.search_icon}>
        <SearchIcon />
      </div>
      <input type="text" />
    </div>
  );
}

export default Search;
