import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Appointments from './layouts/Appointments';
import Layouts from './layouts';
import Login from './layouts/LoginPage';
import SignUp from './layouts/SignupPage';
import CallList from './layouts/CallList';
import SignInContextProvider from './contexts/signin';

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <SignInContextProvider>
                  <Layouts />
                </SignInContextProvider>
              }
            >
              <Route index element={<Appointments />} />
              <Route path="appointments" element={<Appointments />} />
              <Route path="appointments/:apptSlot" element={<Appointments />} />
              <Route path="call-list/:type" element={<CallList />} />
            </Route>
            <Route
              path="/login"
              element={
                <SignInContextProvider>
                  <Login />
                </SignInContextProvider>
              }
            />
            <Route
              path="/signup"
              element={
                <SignInContextProvider>
                  <SignUp />
                </SignInContextProvider>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
