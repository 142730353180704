import React from 'react';
import classes from './Header.module.css';

interface HeaderMenuProps {
  name?: string;
  designation: string;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[] | boolean;
  avatar: JSX.Element;
}

function HeaderMenu({ name, designation, children, avatar }: HeaderMenuProps) {
  return (
    <>
      <div className={`${classes.avatarContainer} d-flex flex-row`}>
        <div className={classes.avatar}>{avatar}</div>
        <div className={`${classes.nameContainer} d-flex flex-column`}>
          <div className={classes.headername}>{name}</div>
          <div className={classes.headersubname}>{designation}</div>
        </div>
      </div>
      <div style={{ position: 'absolute', top: 80 }}>{children}</div>
    </>
  );
}

export default HeaderMenu;
